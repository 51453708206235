import { Button, PropTypes as ButtonProps } from "@jmc/solid-design-system/src/components/atoms/Button/Button";
import { Colors, Spinner } from "@jmc/solid-design-system/src/components/atoms/Spinner/Spinner";
import classnames from "classnames";
import React from "react";

interface LoadingButtonProps extends ButtonProps {
    children: string | JSX.Element;
    loading?: boolean;
    className?: string;
    spinnerColor?: Colors;
}

const renderSpinner = ({ loading = false, spinnerColor = "white" }: LoadingButtonProps): JSX.Element => {
    return loading && <Spinner size="small" color={spinnerColor} />;
};

export const LoadingButton = (props: LoadingButtonProps): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { loading, className, ...other } = props;
    return (
        <Button {...other} accessoryRight={renderSpinner(props)} className={classnames(className ? className : null)}>
            {props.children}
        </Button>
    );
};
